import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class TechSpecsService {
    http;
    apiUrl = `${environment.base}/api/v1/tech-specs`;
    constructor(http) {
        this.http = http;
    }
    findAll(ctx) {
        return this.http.get(this.apiUrl, { params: ctx });
    }
    findOne(id) {
        return this.http.get(`${this.apiUrl}/${id}`);
    }
    create(techSpec) {
        const formData = new FormData();
        Object.keys(techSpec).forEach(key => {
            if (key === 'file') {
                formData.append('file', techSpec[key]);
            }
            else {
                formData.append(key, techSpec[key]);
            }
        });
        return this.http.post(this.apiUrl, formData);
    }
    update(id, techSpec) {
        return this.http.patch(`${this.apiUrl}/${id}`, techSpec);
    }
    remove(id) {
        return this.http.delete(`${this.apiUrl}/${id}`);
    }
    static ɵfac = function TechSpecsService_Factory(t) { return new (t || TechSpecsService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: TechSpecsService, factory: TechSpecsService.ɵfac });
}
