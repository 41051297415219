export var EPriorityColor;
(function (EPriorityColor) {
    EPriorityColor["WARN"] = "warning";
    EPriorityColor["DANGER"] = "danger";
    EPriorityColor["SUCCESS"] = "success";
    EPriorityColor["INFO"] = "info";
})(EPriorityColor || (EPriorityColor = {}));
export var EVarType;
(function (EVarType) {
    EVarType["STRING"] = "string";
    EVarType["INT"] = "int";
    EVarType["FLOAT"] = "float";
    EVarType["JSON"] = "json";
    EVarType["SECRET"] = "secret";
})(EVarType || (EVarType = {}));
export var EUserRole;
(function (EUserRole) {
    EUserRole["SYSOP"] = "sysop";
    EUserRole["ADMIN"] = "admin";
    EUserRole["READONLY"] = "readonly";
    EUserRole["FINANCE"] = "finance";
    EUserRole["EDITOR"] = "editor";
    EUserRole["DESIGNER"] = "designer";
    EUserRole["API"] = "api";
    EUserRole["CUSTOMER"] = "customer";
    EUserRole["EXTERNAL"] = "external";
})(EUserRole || (EUserRole = {}));
export var EPaymentMethod;
(function (EPaymentMethod) {
    EPaymentMethod["PREPAYMET"] = "prepayment";
    EPaymentMethod["PROFORMA"] = "proforma";
    EPaymentMethod["COD"] = "cod";
    EPaymentMethod["PAYPAL"] = "paypal";
})(EPaymentMethod || (EPaymentMethod = {}));
export var EPaymentStep;
(function (EPaymentStep) {
    EPaymentStep["INIT"] = "init";
    EPaymentStep["AUTHORIZE"] = "authorize";
    EPaymentStep["PAID"] = "paid";
})(EPaymentStep || (EPaymentStep = {}));
export var EItemType;
(function (EItemType) {
    EItemType["USER"] = "user";
    EItemType["INVOICE"] = "invoice";
    EItemType["PROFORMA_INVOICE"] = "proforma_invoice";
    EItemType["DELIVERY_NOTE"] = "delivery_note";
    EItemType["ORDER"] = "order";
    EItemType["ESTIMATE"] = "estimate";
    EItemType["USER_AVATAR"] = "user_avatar";
    EItemType["ORDER_MEDIA"] = "order_media";
    EItemType["APPROVE_MEDIA"] = "approve_media";
    EItemType["ORDER_BG"] = "order_bg";
    EItemType["USER_MEDIA"] = "user_media";
    EItemType["CLIENT_MEDIA"] = "client_media";
    EItemType["PUBLIC_MEDIA"] = "public_media";
    EItemType["DATA_JSON"] = "data_json";
    EItemType["DOCUMENT"] = "document";
    EItemType["DOC"] = "doc";
    EItemType["PARTNER"] = "partner";
    EItemType["PARTNER_CONTACT"] = "partner_contact";
    EItemType["PRODUCT"] = "product";
    EItemType["CATEGORY"] = "category";
    EItemType["SERVICE"] = "service";
    EItemType["PRICE"] = "price";
    EItemType["EMAIL_TEMPLATE"] = "email_template";
    EItemType["SMS_TEMPLATE"] = "sms_template";
    EItemType["ORGANIZER_COLUMN"] = "organizer_column";
    EItemType["ORDER_STATE"] = "order_state";
    EItemType["SYSTEM_SETTINGS"] = "system_settings";
    EItemType["PROJECT"] = "project";
    EItemType["CLIP"] = "clip";
    EItemType["TEXT"] = "text";
    EItemType["PDF_PAGE_IMG"] = "pdf_page_img";
    EItemType["APPROVAL_PAGE"] = "approval_page";
    EItemType["FORM_DOCUMENT"] = "form_document";
    EItemType["FORM_DOCUMENT_PAGE"] = "form_document_page";
    EItemType["FORM_FILLED"] = "form_filled";
    EItemType["REVISION_APPROVED"] = "revision_approved";
    EItemType["REVISION_REQUESTED"] = "revision_requested";
    EItemType["COMPLAINT_CREATED"] = "complaint_created";
    EItemType["COMPLAINT_RESOLVED"] = "complaint_resolved";
    EItemType["WORK_DONE"] = "work_done";
    EItemType["TECH_SPEC_FILE"] = "tech_spec_file";
})(EItemType || (EItemType = {}));
export var EContext;
(function (EContext) {
    EContext["ORDER"] = "order";
    EContext["USER"] = "user";
})(EContext || (EContext = {}));
export var ESystemTag;
(function (ESystemTag) {
    ESystemTag["CONVERTED"] = "CONVERTED;info";
    ESystemTag["APPROVAL"] = "APPROVAL;info";
    ESystemTag["APPROVED"] = "APPROVED;info";
})(ESystemTag || (ESystemTag = {}));
export var EEvent;
(function (EEvent) {
    EEvent["LOGIN"] = "login";
    EEvent["VIEW"] = "view";
    EEvent["UPLOAD"] = "upload";
    EEvent["UPLOAD_FTP"] = "upload";
    EEvent["CREATE"] = "create";
    EEvent["UPDATE"] = "update";
    EEvent["ERROR"] = "error";
    EEvent["CREATE_INVOICE"] = "create_invoice";
    EEvent["CREATE_ESTIMATE"] = "create_estimate";
    EEvent["CREATE_DELIVERY_NOTE"] = "create_delivery_note";
    EEvent["SEND_SMS"] = "send_sms";
    EEvent["SEND_EMAIL"] = "send_email";
    EEvent["WORK_DONE"] = "work_done";
    EEvent["SIGN"] = "sign";
    EEvent["LOG"] = "log";
    EEvent["UPDATE_COLUMN"] = "update_column";
    EEvent["UPDATE_STATUS"] = "update_status";
    EEvent["LOG_TIME"] = "log_time";
    EEvent["REMOVE_LOG_TIME"] = "remove_log_time";
    EEvent["CONVERT"] = "convert";
    EEvent["CREATE_FILLED_FORM"] = "create_filled_form";
})(EEvent || (EEvent = {}));
export var XLSXCOLS;
(function (XLSXCOLS) {
    XLSXCOLS[XLSXCOLS["TYPE"] = 0] = "TYPE";
    XLSXCOLS[XLSXCOLS["PRODUCT_ID"] = 1] = "PRODUCT_ID";
    XLSXCOLS[XLSXCOLS["CAT_ID"] = 2] = "CAT_ID";
    XLSXCOLS[XLSXCOLS["SERVICE_ID"] = 3] = "SERVICE_ID";
    XLSXCOLS[XLSXCOLS["TITLE"] = 4] = "TITLE";
    XLSXCOLS[XLSXCOLS["QTY"] = 4] = "QTY";
    XLSXCOLS[XLSXCOLS["SHORT"] = 5] = "SHORT";
    XLSXCOLS[XLSXCOLS["FIXED"] = 5] = "FIXED";
    XLSXCOLS[XLSXCOLS["MU"] = 6] = "MU";
    XLSXCOLS[XLSXCOLS["FIXED_PRICE"] = 6] = "FIXED_PRICE";
    XLSXCOLS[XLSXCOLS["MATERIALS"] = 7] = "MATERIALS";
    XLSXCOLS[XLSXCOLS["PRICE"] = 7] = "PRICE";
    XLSXCOLS[XLSXCOLS["MxM"] = 8] = "MxM";
    XLSXCOLS[XLSXCOLS["VAT"] = 8] = "VAT";
    XLSXCOLS[XLSXCOLS["MAX_DISC"] = 9] = "MAX_DISC";
    XLSXCOLS[XLSXCOLS["NOTES"] = 10] = "NOTES";
    XLSXCOLS[XLSXCOLS["MODEL"] = 11] = "MODEL";
    XLSXCOLS[XLSXCOLS["TIME_PER_MU"] = 12] = "TIME_PER_MU";
    XLSXCOLS[XLSXCOLS["TIME_FIXED"] = 13] = "TIME_FIXED";
    XLSXCOLS[XLSXCOLS["PRICE_MxM"] = 11] = "PRICE_MxM";
    XLSXCOLS[XLSXCOLS["DEFAULT"] = 9] = "DEFAULT";
    XLSXCOLS[XLSXCOLS["MARGIN_BASED"] = 14] = "MARGIN_BASED";
})(XLSXCOLS || (XLSXCOLS = {}));
