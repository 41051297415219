import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { DocumentService } from './document.service';
import { EventsService } from './events.service';
import { InvoicingService } from './invoicing.service';
import { OrderService } from './order.service';
import { PartnersService } from './partners.service';
import { ServicesService } from './services.service';
import { SettingsService } from './settings.service';
import { UserService } from './user.service';
import { MessageService } from 'primeng/api';
import { NotificationService } from './notification.service';
import { OrderChangeDetectionService } from './order-change-detection.service';
import { FinanceService } from './finance.service';
import { PlanService } from './plan.service';
import { RegistrationService } from './registration.service';
import { PricingService } from './pricing.service';
import { FormatsService } from './formats.service';
import { ClipService } from './clip.service';
import { ProjectsService } from './projects.service';
import { KnowledgeService } from './knowledge.service';
import { FormsService } from './forms.service';
import { FoldersService } from './folders.service';
import { DocService } from './doc.service';
import { CostCenterService } from './cost-center.service';
import { LabelCalcService } from './label-calc.service';
import { ComplaintsService } from './complaints.service';
import { ApprovalsService } from './approvals.service';
import { SystemMessagingService } from './system-messaging.service';
import { TechSpecsService } from './tech-specs.service';
import * as i0 from "@angular/core";
export class ApiModule {
    static ɵfac = function ApiModule_Factory(t) { return new (t || ApiModule)(); };
    static ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: ApiModule });
    static ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ providers: [
            ServicesService,
            SettingsService,
            PartnersService,
            OrderService,
            UserService,
            DocumentService,
            InvoicingService,
            EventsService,
            MessageService,
            NotificationService,
            OrderChangeDetectionService,
            FinanceService,
            PlanService,
            RegistrationService,
            PricingService,
            FormatsService,
            ClipService,
            ProjectsService,
            KnowledgeService,
            FormsService,
            FoldersService,
            DocService,
            CostCenterService,
            LabelCalcService,
            ComplaintsService,
            ApprovalsService,
            SystemMessagingService,
            TechSpecsService,
        ], imports: [HttpClientModule,
            CommonModule, HttpClientModule] });
}
