/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { API } from './apiutil';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class CostCenterService {
    http;
    constructor(http) {
        this.http = http;
    }
    create(data) {
        return this.http.post(`${environment.base}/cost-center`, data);
    }
    search(ctx) {
        return this.http.get(`${environment.base}/cost-center`, {
            params: API.makeParams(ctx),
        });
    }
    getCostCenter(id) {
        return this.http.get(`${environment.base}/cost-center/${id}`);
    }
    updateCostCenter(id, payload) {
        return this.http.put(`${environment.base}/cost-center/${id}`, payload);
    }
    searchUsages(ctx) {
        return this.http.get(`${environment.base}/cost-center/usage`, {
            params: API.makeParams(ctx),
        });
    }
    createUsage(data) {
        return this.http.post(`${environment.base}/cost-center/usage`, data);
    }
    updateUsage(id, data) {
        return this.http.put(`${environment.base}/cost-center/usage/${id}`, data);
    }
    deleteUsage(id) {
        return this.http.delete(`${environment.base}/cost-center/usage/${id}`);
    }
    static ɵfac = function CostCenterService_Factory(t) { return new (t || CostCenterService)(i0.ɵɵinject(i1.HttpClient)); };
    static ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: CostCenterService, factory: CostCenterService.ɵfac });
}
